import React, { Component } from 'react';
import { Container, Title, Columns, Column, Image, Section } from 'bloomer';

import Logo from '../../assets/logo.jpg';

export default class Home extends Component {
    render() {
        const style = {
            logo: {
                height: '128px',
                width: '128px'
            },
            links: {
                textDecoration: 'none',
                padding: '3px',
                fontWeight: '500',
                display: 'inline'
            }
        }

        return (
            <Container style={{ marginTop: 128 }}>
                <Columns isCentered>
                    <Column isSize="1/1">
                        <Section>
                            <Image src={Logo} className="logo"/>
                            <Title isSize={6}>
                                Hi. Unfortunately, I'm a software engineer.
                            </Title>
                        </Section>
                        <Section>
                            <ul className="links" style={style.links}>
                                <li className="link"><a style={style.links} href="http://gregblogs.com/">Blog</a></li>
                                <li className="link"><a style={style.links} href="http://twitter.com/quansai">Twitter</a></li>
                                <li className="link"><a style={style.links} href="http://instagram.com/gregthompsonjr">Instagram</a></li>
                            </ul>
                        </Section>
                        <Section>
                            <p className="email">GregTheCodeNinja @ gmail . com</p>
                        </Section>
                    </Column>
                </Columns>
            </Container>
        )
    }
}